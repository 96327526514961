<template>
  <div>
    <slot name="head">
    </slot>
    <div class="team__head">
      <cpr-input
        v-model="buscar"
        id="buscar"
        placeholder="Busque aqui..."
        :prefix-icon="'el-icon-search'"
        :suffix-icon="buscar ? 'el-icon-close' : ''"
        :aria-autocomplete="false"
        autocomplete="off"
        @click-suffix="toggle"
      />

      <el-button 
        v-if="!!criar" 
        type="primary"
        class="btn btn-primary"
        style="width: 100%" 
        @click="criar"
      >
        <i class="el-icon-plus"/>
      {{ botaoAdicionar }}
      </el-button>
    </div>

    <div class="team__body">
      <div class="team__body-empty" v-if="loadData">
        <cpr-icon iconName="icon-search" />
        <h3>Aguarde...</h3>
        <p class="p1">Carregando os dados...</p>
      </div>

      <div class="table" v-else>
        <el-table
          header-cell-class-name="team__header"
          :data="handleFiltro"
        >
          <el-table-column
            v-for="item in colunas"
            :key="item.label"
            :prop="item.prop"
            :label="item.label.toUpperCase()"
            :width="item.width"
            :sortable="item.sortable"
          />
          <slot name="column"/>
          <el-table-column 
            v-if="acoes"
            class-name="table__actions"
            label="AÇÕES"
            align="center"
            prop="acoes" 
            width="160px"
          >
            <template v-slot="scope">
              <el-button-group>
                <el-button 
                  v-if="acoes.find( a => a === 'visualizar')"
                  size="mini" icon="el-icon-document" 
                  @click="visualizar(scope)"
                />
                <el-button 
                  v-if="acoes.find( a => a === 'editar')"
                  size="mini" icon="el-icon-edit" 
                  @click="editar(scope)"
                />
                <el-button 
                  v-if="acoes.find( a => a === 'deletar')"
                  size="mini" 
                  type="danger" 
                  icon="el-icon-delete" 
                  @click="deletar(scope)"
                />
              </el-button-group>
            </template>
          </el-table-column>

          <template #empty>
            <div class="team__body-empty">
              <cpr-icon iconName="icon-search" />
              <h3>{{ vazio }}</h3>
              <p class="p1">
                Confira a grafia e busque outra vez, ou realize um novo
                cadastro.
              </p>
            </div>
          </template>
        </el-table>

        <div v-if="data.length > tamanhoPorPagina" class="team__table-footer">
          <el-pagination
            :page-size="tamanhoPorPagina"
            :current-page="page"
            hide-on-single-page
            :total="data.length"
            layout="prev, pager, next, jumper"
            @current-change="handlePage"
          />
        </div>
      </div>
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import CprIcon from '@/components/CprIcon.vue';
import CprInput from '@/components/Input.vue';
import { ITableColumn } from '~/types/table';

type Acoes = 'visualizar' | 'deletar' | 'editar'

@Component({
  components:{
    CprIcon,
    CprInput
  }
})
export default class CprListTable<T = object> extends Vue {
  @Prop({ default: [{}] , required: true }) colunas!:ITableColumn<T>[];
  @Prop({ default: {}, required: true }) data!:T[];
  @Prop( ) filtro!:(a:string) => ( [] );
  /** Adicionar lógica para filtros async */
  @Prop( ) filtroAsync!:(a:string) => Promise<( [] )>;
  @Prop({ default: 10 }) tamanhoPorPagina!:number;
  @Prop({ default: 'Nenhum dado encontrado!'}) vazio!:string;
  @Prop({ default: 'Novo'}) botaoAdicionar!:string;
  @Prop({ default: true , type: Boolean}) loadData!:boolean;
  @Prop() acoes!:Acoes[];
  @Prop() deletar!: (row) => Promise<void>;
  @Prop() editar!: (row) => Promise<void>;
  @Prop() visualizar!: (row) => Promise<void>;
  @Prop() criar!: () => void;

  showBuscar = false;
  buscar = '';
  page = 1;
  loading = true;

  get handleFiltro(){
    const filtragem = typeof (this.filtro) === 'function' ? this.filtro(this.buscar.toLowerCase()) : this.data ;
    return filtragem
      .slice((this.page - 1) * this.tamanhoPorPagina, this.page * this.tamanhoPorPagina
    ) 
  }

  toggle() {
    this.buscar = '';
    this.showBuscar = !this.showBuscar;
  }

  handlePage(page) {
    this.page = page;
  }

  handleGoPage(value) {
    const newPage = Number(value);

    const rest = this.data.length % this.tamanhoPorPagina === 0 ? 0 : 1;

    const totalPages = Math.trunc(this.data.length / this.tamanhoPorPagina) + rest;

    if (newPage < 1) {
      this.page = 1;

      return 1;
    }

    if (newPage > totalPages) {
      this.page = totalPages;

      return ;
    }

    this.page = newPage;
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table {
  &__actions{
    & > :nth-child(n){
      justify-content:center;

    }
  }
}
</style>
