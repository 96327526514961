import Vue from 'vue';
import VueRouter from 'vue-router';
import Blank from './layouts/blank.vue';
import Empty from './layouts/empty.vue';
import FormEmitente from './views/App/Emitente/Form.vue';
import ListEmitente from './views/App/Emitente/Team.vue';
import Favorecidos from './views/App/Favorecidos/Favorecidos.vue';
import TeamFavoritos from './views/App/Favorecidos/TeamFavoritos.vue';
import ListFazendas from './views/App/Fazenda/List.vue';
import FormFazenda from './views/App/Fazenda/Form.vue';
import ListLocaisEntrega from './views/App/LocaisEntrega/List.vue';
import FormLocaisEntrega from './views/App/LocaisEntrega/Form.vue';
import ListEndossatarios from './views/App/Endossatario/List.vue';
import store from './store';
import admin from '~/views/admin/index.routes';
import auth from '~/views/auth/index.routes';
import management from '~/views/management/index.routes';
import aprovacao from '~/views/App/Aprovacao/index.routes';
import { Dictionary } from 'vue-router/types/router';

/**
 * Install Vue Router.
 */
Vue.use(VueRouter);

/**
 * Team routes.
 */
const team = {
  path: 'emitentes',
  component: Blank,
  children: [
    {
      path: '',
      component: ListEmitente,
      name: 'emitentes',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Emitentes'
      },
    },
    {
      path: '/emitentes/editar/:id?',
      component: FormEmitente,
      name: 'EditEmitente',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/emitentes/novo',
      component: FormEmitente,
      name: 'CreateEmitente',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

const teamFavoritos = {
  path: 'favorecidos',
  component: Blank,
  children: [
    {
      path: '',
      name: 'TeamFavoritos',
      component: TeamFavoritos,
      meta: {
        requiresAuth: true,
        breadcrumb: 'Favorecidos'
      },
    },
    {
      path: '/favorecidos/novo',
      component: Favorecidos,
      name: 'CreateFavorecido',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/favorecidos/editar/:id?',
      component: Favorecidos,
      name: 'EditFavorecido',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

const fazendas = {
  path: 'fazendas',
  component: Blank,
  children: [
    {
      path: '',
      name: 'fazendas',
      component: ListFazendas,
      meta: {
        requiresAuth: true,
        breadcrumb: 'Fazendas'
      },
    },
    {
      path: '/fazendas/novo',
      component: FormFazenda,
      name: 'CreateFazenda',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/fazendas/editar/:id?',
      component: FormFazenda,
      name: 'EditFazenda',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

const endossatario = {
  path: 'endossatarios',
  component: Blank,
  children: [
    {
      path: '',
      name: 'endossatarios',
      component: ListEndossatarios,
      meta: {
        requiresAuth: true,
        breadcrumb: 'Endossatários'
      },
    }
  ],
}

const locaisEntrega = {
  path: 'locais-entrega',
  component: Blank,
  children: [
    {
      path: '',
      name: 'locais-entrega',
      component: ListLocaisEntrega,
      meta: {
        requiresAuth: true,
        breadcrumb: 'Locais de Entrega'
      },
    },
    {
      path: '/locais-entrega/novo',
      component: FormLocaisEntrega,
      name: 'CreateLocais',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/locais-entrega/editar/:id?',
      component: FormLocaisEntrega,
      name: 'EditLocais',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

/**
 * Main routes.
 */
const main = {
  path: '/',
  component: ()=> import('./layouts/cpr-navigate/index.vue'),
  children: [
    {
      path: '',
      redirect: '/auth/login',
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: ()=> import('./views/App/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: 'Dashboard'
      },
    },
    {
      path: 'titulos',
      component: ()=> import('./views/App/Title/Titles.vue'),
      name: 'Titles',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Títulos'
      },
    },
    team,
    teamFavoritos,
    fazendas,
    locaisEntrega,
    endossatario,
    admin
  ],
};

const titulos = {
  path: '/titulos/:tituloId',
  component: Empty,
  children: [
    {
      path: '',
      redirect: '/auth/login',
    },
    {
      component: ()=> import('./views/App/Title/cpr-fisica/cpr-fisica.vue'),
      name: 'CprFisica',
      path: 'cpr-fisica',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Cadastro de CPR Física'
      }
    },
    {
      component: ()=> import('./views/App/Title/solicitar-endosso/solicitar-endosso.vue'),
      name: 'SolicitarEndosso',
      path: 'solicitar-endosso/:endossoId?',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Solicitar Endosso'
      }
    },
    {
      component: ()=> import('./views/App/Title/adicionar-endosso-registro/adicionar-endosso-registro.vue'),
      name: 'GerenciarEndosso',
      path: 'gerenciar-endosso',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Gerenciar Endosso no Registro'
      }
    },
    {
      path: 'solicitar-liquidacao',
      component: () => import('./views/App/Title/Liquidacao/solicitar-liquidacao.vue'),
      name: 'SolicitarLiquidacao',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Solicitar Liquidação'
      },
    },
    {
      path: 'gerenciar-assinaturas',
      component: () => import('./views/App/Title/gerenciar-assinaturas/gerenciar-assinaturas.vue'),
      name: 'GerenciarAssinaturas',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Gerenciar Assinaturas'
      },
    },
    {
      path: 'detalhamento',
      component: ()=> import('./views/App/Title/Detalhamento/detalhamento-titulo.vue'),
      name: 'Detalhamento',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Detalhamento de arquivos'
      },
    },
  ],
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  fallback: true,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    auth,
    main,
    titulos,
    management,
    aprovacao,
    //fallback ↓
    // { path: '*', component: ()=> import('./layouts/cpr-navigate/index.vue') }
  ],
});

const normalizedQuery = (query:Dictionary<string | (string | null)[]>)=> {
  if(!query) return ''
  const normalizedQuery = Object.fromEntries(
    Object.entries(query).map(([key, value]) => [
      key, 
      Array.isArray(value) ? value.join(',') : value ?? ''
    ])
  );

  return `?${new URLSearchParams(normalizedQuery).toString()}`
}


router.beforeEach((to, from, next) => {
  const authRequired = !!to?.meta?.requiresAuth
  const routeAdmin = !!to?.meta?.routeAdmin
  const accessAdmin = store.getters['auth/accessAdmin']
  const tokenValid = store.getters['auth/loginToken']
  
  const routeName = to?.meta?.breadcrumb ? `${to.meta?.breadcrumb}` : 'Assinei CPR'
  document.title = routeName
  
  if(authRequired && !tokenValid ){
    const { path, query } = to;
    // Monta a query string manualmente
    const queryString = normalizedQuery(query)
    next({ name: 'Login', query: { ...query, redirect: path+queryString } });
  } else if(tokenValid && to.path === '/auth/login'){
    next({ name: 'Dashboard' });
  } else if (routeAdmin && !accessAdmin) {
    next({ name: from.name || 'Dashboard' }); 
  } else {
    next();
  }

});

export default router;
