import { isArray } from 'lodash';

export const validarDataPassada = (data:string | Date | Array<Date> | Array<string>):boolean =>{
  if(isArray(data)){
    return data.map( a => validarDataPassada(a)).some( resultado => resultado)
  }
  const dataPassada: Date = new Date(data); 
  const dataAtual: Date = new Date();
  dataPassada.setHours(0, 0, 0, 0);
  dataAtual.setHours(0, 0, 0, 0);
  
  return dataPassada.getTime() < dataAtual.getTime();
}
