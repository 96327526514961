/* eslint-disable @typescript-eslint/no-explicit-any */
import { isObject } from 'lodash';
import { validarCNPJ } from './validations/cnpj';
import { validateCPF } from './validations/cpf';
import { validarZero } from './validations/zero';
import { validarDataPassada } from './validations/data';
import { retirarFormatacaoNumero } from './formats';

export const validationForm = (rules: any[]) => {
  const formRules: any[] = [];
  rules.forEach((rule: any) => {
    const key = isObject(rule) ? Object.keys(rule)[0] : rule;
    switch (key) {
      case 'required':
        formRules.push({
          validator: (r: any, value: any, callback: (error?: Error) => void) => {
            if (!value || value?.length === 0) callback(new Error('Campo obrigatório!'));
            else  callback()
          },
          trigger: ['blur', 'change'],
          required: true
        });
      break;

      case 'numberRequired':
        formRules.push({
          type: 'number',
          required: true,
          message: 'Campo obrigatório!',
          trigger: ['blur', 'change'],
        });
      break;

      case 'dataPassada':
        formRules.push({
          validator: (r: any, value: any, callback: (error?: Error) => void) => {
            if (validarDataPassada(value)) callback(new Error('Não é possível definir uma data do passado'));
            else  callback()
          },
          trigger: ['blur', 'change'],
        });
      break;

      case 'email':
        formRules.push({
          type: 'email',
          required: false,
          message: 'Digite um email válido!',
          trigger: ['blur', 'change'],
        });
      break;

      case 'between':
        formRules.push({
          min: rule.between.min,
          max: rule.between.max,
          message: `Tamanho permitido entre ${rule.between.min} a ${rule.between.max} caracteres`,
          trigger: ['blur', 'change'],
        });
      break;

      case 'numberBetween':
        formRules.push({
          message: `Valor permitido entre ${rule.numberBetween.min} e ${rule.numberBetween.max}`,
          trigger: ['blur', 'change'],
          validator: (r:any, value:string, callback: (arg?: Error ) => void) => {           
            if(!value) return callback();
          
            const numberValue = retirarFormatacaoNumero(value)
            if (isNaN(numberValue) 
              || numberValue < rule.numberBetween.min 
              || numberValue > rule.numberBetween.max) {
              callback(new Error(rule.message));
            } else {
              callback();
            }
          },
        });
      break;

      case 'cpf':
        formRules.push({
          validator: (r: any, value: string, callback: (arg?: Error ) => void) => {
            value ? validateCPF(value) 
              ? callback() 
              : callback(new Error('CPF inválido!')) 
            : callback();
          }
        });
      break;

      case 'cnpj':
        formRules.push({
          validator: (r: any, value: string, callback: (arg?: Error ) => void) => {
            value ? validarCNPJ(value) 
              ? callback() 
              : callback(new Error('CNPJ inválido!')) 
            : callback();
          }
        });
      break;

      case 'max':
        formRules.push({
          validator: (r: any, value: string, callback: (arg?: Error ) => void) => {
            const valueLength = value?.length ?? 0
            valueLength <= rule?.max ? callback() : callback(new Error(`Tamanho máximo permitido de ${rule.max} caracteres`))
          }
        });
      break;

      case 'zero':
        formRules.push({
          validator: (r: any, value: string | number, callback: (arg?: Error) => void) => {
            if (validarZero(value)) callback(new Error('O número não pode ser zero.'))
            else callback()
          }
        });
      break;
    }
  });
  return formRules;
}
