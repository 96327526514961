import Vue from 'vue';
import Vuex from 'vuex';
import auth, { type AuthState } from './modules/auth';
import parametrizacoes, { type ParametrizacaoState } from './modules/parametrizacao';

export interface RootState {
  auth: AuthState;
  parametrizacoes: ParametrizacaoState
}

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    auth,
    parametrizacoes
  },
  strict: process.env.NODE_ENV !== 'production',
});
