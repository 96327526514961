<template>
  <div class="team">
    <div class="team__head">
      <button
        class="icon-button is-filled"
        v-if="!show"
        @click.prevent="toggle"
      >
        <IconSearch />
      </button>

      <el-input v-model="search" v-else placeholder="Buscar">
        <template #suffix>
          <i
            class="el-input__icon el-icon-close"
            style="cursor: pointer"
            @click.prevent="toggle"
          />
        </template>
      </el-input>

      <el-button type="primary" @click="$router.push({ name: 'CreateLocais' })"
        >Novo Local de Entrega</el-button
      >
    </div>

    <div class="team__body">
      <div class="team__body-empty" v-if="loading">
        <span>
          <IconStore />
        </span>
        <h3>Aguarde...</h3>
        <p class="p1">Carregando os Locais de Entrega...</p>
      </div>

      <div class="table" v-else>
        <el-table
          header-cell-class-name="team__header"
          :data="filtered.slice((page - 1) * 10, page * 10)"
        >
          <el-table-column
            v-for="item in head"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
          />
          <el-table-column 
            class-name="table__actions"
            label="Ações"
            align="center"
            prop="acoes" 
            width="160px"
          >
            <template v-slot="scope">
                <el-button-group>
                  <el-button size="mini" icon="el-icon-edit" 
                    @click="handleClick(locaisEntrega[scope.$index])"/>
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteLocal(scope.$index)"/>
                </el-button-group>
            </template>
          </el-table-column>

          <template #empty>
            <div class="team__body-empty">
              <span>
                <IconSearch />
              </span>
              <h3>Nenhum local de entrega encontrado!</h3>
              <p class="p1">
                Confira a grafia e busque outra vez, ou realize um novo
                cadastro.
              </p>
            </div>
          </template>
        </el-table>

        <div v-if="locaisEntrega.length > 10" class="team__table-footer">
          <el-pagination
            :page-size="10"
            :current-page="page"
            hide-on-single-page
            :total="locaisEntrega.length"
            layout="prev, pager, next, jumper"
            @current-change="handlePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/** @todo Componentizar layout de listagem */
import IconStore from '../../../assets/svgs/icon-store.svg';
import IconSearch from '../../../assets/svgs/icon-search.svg';
import ServiceLocalEntrega from '../../../services/LocalEntrega';

export default {
  name:'list-local-entrega',
  components: {
    IconStore,
    IconSearch,
  },

  data: () => ({
    show: false,
    locaisEntrega: [],
    search: '',
    page: 1,
    loading: true,
  }),

  async mounted() {
    await this.obterLocaisEntrega();
  },

  computed: {
    head() {
      return [
        {
          prop: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          prop: 'endereco',
          label: 'Endereço',
          sortable: true,
        },
        {
          prop: 'uf',
          label: 'UF',
          width: 200,
        },
        {
          prop: 'municipio',
          label: 'Município',
          sortable: true,
        },
      ];
    },

    filtered() {
      const { locaisEntrega, search } = this;

      const lowered = search.toLowerCase();
      const items = locaisEntrega?.filter(
        ({
          nome, endereco, municipio,
        }) => (nome && nome.toLowerCase().indexOf(lowered) !== -1)
          || (endereco && endereco.toString().toLowerCase().indexOf(lowered) !== -1)
          || (municipio
            && municipio.toLowerCase().indexOf(lowered) !== -1)
      );

      if (!search) return locaisEntrega;

      return items;
    },
  },

  methods: {
    async obterLocaisEntrega() {
      this.loading = true;
      try {
        this.locaisEntrega = (await ServiceLocalEntrega.getAll()).data;
      } catch (er) {
        this.$showMessagesErrors(er)
      } finally {
        this.loading = false;
      }
    },

    toggle() {
      this.search = '';
      this.show = !this.show;
    },

    handleClick({ id }) {
      this.$router.push({
        params: { id },
        name: 'EditLocais',
      });
    },

    async deleteLocal(index) {
      try {
        await ServiceLocalEntrega.deleteById(this.locaisEntrega[index].id)
        this.$showNotify('success', `O local ${this.locaisEntrega[index].nome} foi removido com sucesso!`)
        this.locaisEntrega?.splice(index, 1);
      } catch (error) {
        this.$showMessagesErrors(error)
      }
    },

    handlePage(page) {
      this.page = page;
    },

    handleGoPage(value) {
      const newPage = Number(value);

      const rest = this.locaisEntrega.length % 10 === 0 ? 0 : 1;

      const totalPages = Math.trunc(this.locaisEntrega.length / 10) + rest;

      if (newPage < 1) {
        this.page = 1;

        return;
      }

      if (newPage > totalPages) {
        this.page = totalPages;

        return;
      }

      this.page = newPage;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
