<template>
  <div class="team">
    <div class="team__head">
      <button
        class="icon-button is-filled"
        v-if="!show"
        @click.prevent="toggle"
      >
        <IconSearch />
      </button>

      <el-input v-model="search" v-else placeholder="Buscar">
        <template #suffix>
          <i
            class="el-input__icon el-icon-close"
            style="cursor: pointer"
            @click.prevent="toggle"
          />
        </template>
      </el-input>

      <el-button type="primary" @click="$router.push({ name: 'CreateFazenda' })"
        >Nova Fazenda</el-button
      >
    </div>

    <div class="team__body">
      <div class="team__body-empty" v-if="loading">
        <span>
          <IconFarm />
        </span>
        <h3>Aguarde...</h3>
        <p class="p1">Carregando as fazendas...</p>
      </div>

      <div class="table" v-else>
        <el-table
          header-cell-class-name="team__header"
          :data="filtered.slice((page - 1) * 10, page * 10)"
        >
          <el-table-column
            v-for="item in head"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
          />
          <el-table-column 
            class-name="table__actions"
            label="Ações"
            align="center"
            prop="acoes" 
            width="160px"
          >
            <template v-slot="scope">
                <el-button-group>
                  <el-button size="mini" icon="el-icon-edit" 
                    @click="handleClick(fazendas[scope.$index])"/>
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteFazenda(scope.$index)"/>
                </el-button-group>
            </template>
          </el-table-column>

          <template #empty>
            <div class="team__body-empty">
              <span>
                <IconSearch />
              </span>
              <h3>Nenhuma fazenda encontrada</h3>
              <p class="p1">
                Confira a grafia e busque outra vez, ou realize um novo
                cadastro.
              </p>
            </div>
          </template>
        </el-table>

        <div v-if="fazendas.length > 10" class="team__table-footer">
          <el-pagination
            :page-size="10"
            :current-page="page"
            hide-on-single-page
            :total="fazendas.length"
            layout="prev, pager, next, jumper"
            @current-change="handlePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFarm from '../../../assets/svgs/icon-farm.svg';
import IconSearch from '../../../assets/svgs/icon-search.svg';
import ServiceFazenda from '../../../services/Fazenda';

export default {
  name:'list-fazendas',
  components: {
    IconFarm,
    IconSearch,
  },

  data: () => ({
    show: false,
    fazendas: [],
    search: '',
    page: 1,
    loading: true,
  }),

  mounted() {
    this.obterFazendas();
  },

  computed: {
    head() {
      return [
        {
          prop: 'nomeFazenda',
          label: 'Nome',
          sortable: true,
        },
        {
          prop: 'areaTotal',
          label: 'Área Total(ha)',
          width: 200,
        },
        {
          prop: 'numeroMatricula',
          label: 'Nº Matrícula',
          sortable: true,
        },
      ];
    },

    filtered() {
      const { fazendas, search } = this;

      const lowered = search.toLowerCase();
      const items = fazendas?.filter(
        ({
          nomeFazenda, areaTotal, numeroMatricula,
        }) => (nomeFazenda && nomeFazenda.toLowerCase().indexOf(lowered) !== -1)
          || (areaTotal && areaTotal.toString().toLowerCase().indexOf(lowered) !== -1)
          || (numeroMatricula
            && numeroMatricula.toLowerCase().indexOf(lowered) !== -1)
      );

      if (!search) return fazendas;

      return items;
    },
  },

  methods: {
    async obterFazendas() {
      this.loading = true;
      try {
        this.fazendas = (await ServiceFazenda.getAll()).data;
      } catch (er) {
        this.$showMessagesErrors(er)
      } finally {
        this.loading = false;
      }
    },

    toggle() {
      this.search = '';
      this.show = !this.show;
    },

    handleClick({ id }) {
      this.$router.push({
        params: { id },
        name: 'EditFazenda',
      });
    },

    handlePage(page) {
      this.page = page;
    },

    handleGoPage(value) {
      const newPage = Number(value);

      const rest = this.fazendas.length % 10 === 0 ? 0 : 1;

      const totalPages = Math.trunc(this.fazendas.length / 10) + rest;

      if (newPage < 1) {
        this.page = 1;

        return;
      }

      if (newPage > totalPages) {
        this.page = totalPages;

        return;
      }

      this.page = newPage;
    },
    async deleteFazenda(index) {
      this.$cprAlert({
        type: 'delete',
        title: 'Excluindo Fazenda!',
        textInfo: `Você está excluindo a fazenda <b>${this.fazendas[index].nomeFazenda}</b>. Caso confirme, os dados serão ` +
          '<b>excluídos</b> e <b>não poderão ser recuperados</b>. O que você deseja fazer?',
        namePositiveAction: 'Manter Fazenda',
        nameNegativeAction: 'Excluir Fazenda',
        negativeAction: async () => {
          try {
            await ServiceFazenda.deleteById(this.fazendas[index].id)
            this.$showNotify('success', `A Fazenda ${this.fazendas[index].nomeFazenda} foi removida com sucesso!`)
            this.fazendas?.splice(index, 1);
          } catch (error) {
            this.$showMessagesErrors(error)
          }
        }
      })
    },
  },
};
</script>

<style lang="scss">
.team {
  &__head {
    width: fit-content;
    display: flex;
    margin-left: auto;

    button {
      margin-left: 24px;
    }

    .el-input {
      min-width: 316px;
    }
  }

  &__body {
    margin-top: 32px;

    &-empty {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      background-color: $--color-gray-1;
      padding: 64px;
      align-items: center;

      span {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 16px;
        justify-content: center;
        background-color: rgba($--color-info, 0.2);

        svg path {
          fill: $--color-info;
        }
      }

      h3 {
        color: $--color-gray-7;
      }

      & p {
        margin-top: 8px;
        color: $--color-gray-6;
        max-width: 272px;
      }
    }
  }

  &__header {
    .cell {
      padding: 0px 0px 0px 10px !important;
    }
  }

  &__table-footer {
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1080px) {
  .team {
    padding: 0px 20px;
    box-sizing: border-box;
  }
}
</style>
