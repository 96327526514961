/* eslint-disable @typescript-eslint/no-explicit-any */
import Methods from './utils/methods';
import Format from './utils/formats';
import { validationForm } from './utils/validationForm';
import {showNotify} from './utils/notificador';
import { MessageType, MessageErrorType, ItemLista } from './types/types';


/** Para adicionar o método à instância do vue acrescentar a assinatura da função
 * @link src\types\index.d.ts
 */ 
export default {
  $clearArray: (val:Array<any>) => Methods.clearArray(val),
  $showNotify: (type: MessageType, message: string, title?: string) => showNotify(type, message, title),
  $showMessagesErrors: (error:any, type?:MessageErrorType, title?:string) => Methods.showCapturedNotification(error, type, title),
  $formatarTelefone: (value: string) => Format.formatarTelefone(value),
  $removeEmptyProperties: (obj: object) => Methods.removeEmptyProperties(obj),
  $formatarCEP: (cep: string, traco?: boolean) => Format.formatarCEP(cep, traco),
  $validationForm: (rule: any[]) => validationForm(rule),
  $removerItemLista:<T extends ItemLista<T>>(params: { id: number | string, lista: T[] }): void => Methods.removerItemLista(params),
  $separarCamelCase: (texto?: string) => Methods.separarCamelCase(texto),
  $console: (a)=> console.log(a),
  $dictionaryConvertion:<T>(obj:T)=> Methods.dictionaryConvertion(obj),
  $focusElement: (element: HTMLElement | null) => Methods.focusElement(element)
}


