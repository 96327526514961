/** Componente baseado na lib abaixo.
*  @link https://element.eleme.io/#/en-US/component/form#form-item-slot
*  Caso necessário, acessar para criar mais validações
*  @see validationForm;
*/
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { Form } from 'element-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { validationForm } from '~/utils/validationForm'

/** @see As propriedades do objeto formModel precisa estar com todas as propriedades que são aninhadas
 * inicializadas. Pois a biblioteca Element não lida automaticamente com propriedades aninhadas ou 
 * arrays complexos em sua validação padrão.
 * */ 
@Component
export default class CprFormValidate extends Vue {
  @Prop({ default: 'Concluir', required: false }) nameSubmit!: string;
  @Prop({ default: 'Voltar', required: false }) nameCancel!: string;
  @Prop({ default: 'formulario', required: false }) refForm!: string;
  @Prop({ default: false, required: false }) iconCheck!: boolean;
  @Prop({ default: false, required: false }) disabled!: boolean;
  @Prop({ default: true, required:  false }) resetFields!: boolean;
  @Prop({ default: false, required: false }) validateBack!: boolean;
  @Prop({ default: false }) loadSubmit!: boolean
  @Prop({ required: true }) formModel!: object;
  @Prop({ required: true }) submit!: () => Promise<void>;
  @Prop({ required: true }) cancel!: () => void | Promise<void>;

  @Provide('resetFieldsForm') 
  resetFieldsForm(){
    const el = this.getFormulario(this.refForm)
    setTimeout(()=> el?.resetFields(), 300)
  }

  $refs = {};
  loading = false;

  protected getFormulario(key: string): Form {
    return this.$refs[key];
  }

  validateForm(action: () => Promise<void>) {
    this.getFormulario(this.refForm).validate(async (valid, invalidFields) => {
      if (valid) {
        /** @todo reavaliar uso desse try catch  */
        try {
          this.loading = !this.loading;

          await action();
          this.loading = !this.loading;

          return;
        } catch(err) {
          throw new Error('Erro ao tentar enviar o formulário', { cause: err } )
        }
      }

      this.$emit('invalid', invalidFields);
      this.focusInvalidFields(invalidFields);
    });
  }

  backForm() {
    this.validateBack ? this.validateForm(this.cancel as () => Promise<void>)
      : this.resetForm();
  }

  resetForm() {
    this.cancel();
    if(this.resetFields) {
      this.resetFieldsForm()
    }
  }

  focusInvalidFields(v: object) {
    const campoInvalido = Object.keys(v);
    const labelFor = campoInvalido[0];

    const labelElement = document.querySelector(`label[for="${labelFor}"]`);
    if (labelElement) {
      const inputElement = labelElement.nextElementSibling;
      if (inputElement) {
        inputElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }
}